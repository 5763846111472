.pup-definitions {
  > * {
    margin: 12px 0;
  }

  .search-input {
    width: 190px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.pup-form {
  .new-button {
    margin-bottom: 12px;
  }

  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .minimal-size {

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .navigation-element {
    font-size: 20px;
  }

  .delete-icon {
    color: red;
    font-size: 20px;
  }

  .add-icon {
    color: #1890ff;
    font-size: 20px;
    margin-right: 5px;
  }

  .params-title {
    display: flex;
    flex: 1;
    justify-content: center;
    border-bottom: 1px solid #e8e8e8;
    height: 40px;
    margin-bottom: 5px;
  }

  .scrolling-container {
    overflow: auto;
    height: 272px;

    &-two-row {
      height: 188px;
      margin-right: 8px;
    }
  }

  .ant-tabs-nav {
    & .tabs-custom {
      height: 40px;
      width: 220px;
      margin: 0;
      margin-right: 2px;
      padding: 0 16px;
      line-height: 38px;
      background: #fafafa;
      border: 1px solid #e8e8e8;
      border-radius: 4px 4px 0 0;
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);

      &.ant-tabs-tab-active {
        height: 40px;
        color: #1890ff;
        background: #fff;
        border-color: #e8e8e8;
        border-bottom: 2px solid #1890ff;

      }
    }
  }
}
