.product-exceptions {
  > * {
    margin: 12px 0;
  }

  .add-button > i {
    vertical-align: -0.125em;
  }

  .eye {
    font-size: 20px;
  }

  .search-input {
    width: 120px;
    color: rgba(0, 0, 0, 0.45);
  }

  .import-button {
    margin-right: 5px;
  }

  .ant-table-selection-column {
    pointer-events: none;

    .ant-checkbox-wrapper {
      pointer-events: all;
    }
  }
}
