.new-button > i {
  vertical-align: -0.125em !important;
  margin-right: 3px;
}

.new-button-help {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.65);
}

.eye {
  font-size: 20px;
}

.multi-select {
  min-width: 150px;
}

.ant-tag-checkable {
  cursor: pointer !important;
}

.ant-input-number.input-number-smaller {
  width: 75px;
}

.parameter-pagination {
  .ant-list-pagination {
    margin-top: 5px;
  }
}

.no-select {
  user-select: none;
}

div.days-picker {
  display: flex;
  height: 40px;
  align-items: center;

  .tag {
    height: 25px;
    line-height: 25px;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
  }
}

.inactive-row {
  font-style: italic;
  color: #a4a4a4;
}

.rule-state-draft {
  & > i {
    padding-right: 5px;
    color: #1890ff;
    font-size: large;
  }
}

.rule-state-final {
  & > i {
    padding-right: 5px;
    color: #009900;
    font-size: large;
  }
}

.rule-state-locked {
  & > i {
    padding-right: 5px;
    color: gray;
    font-size: large;
  }
}

.rule-state-to-delete {
  & > i {
    padding-right: 5px;
    color: #FF0000;
    font-size: large;
  }
}

.c-pointer {
  cursor: pointer;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.position-fixed {
  position: fixed;
}

.nested-table tbody {
  background: white;
}
