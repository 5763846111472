.dashboard-products {
  > * {
    margin-top: 12px;
  }

  .ant-btn-icon-only > i {
    vertical-align: -0.125em;
  }

  .recalculate-button {
    margin-left: 10px;
    margin-right: 10px;
  }

  .label-red {
    color: red;
  }

  .label-green {
    color: #009900;
  }

  .ant-table-body {
    max-height: calc(100vh - 650px);

    @media (min-width: 992px) {
      max-height: calc(100vh - 470px);
    }

    @media (min-width: 1200px) {
      max-height: calc(100vh - 450px);
    }
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }

  .error-icon {
    color: red;
    font-size: 18px;
  }

  .ok-icon {
    color: #00bb00;
    font-size: 18px;
  }
}

.dashboard-product-form {
  label {
    font-weight: bold;
  }

  .separator-row {
    border-top: 1px solid #e8e8e8;
  }

  .ant-form-item-control {
    line-height: 30px;
  }

  .ant-form-item-label {
    line-height: 30px;
  }
}

.product-rules-modal {
  top: 0 !important;
}
