@import './variables';
@import './product-exceptions';
@import './max-discounts';
@import './form-definitions';
@import './shared';
@import './overrides';
@import './login';
@import './dashboard-products';

.app,
#app {
  width: 100%;
  height: 100%;

  .header {
    background: #fff;
    padding: 0;
    .ant-menu {
      line-height: 64px;
      .no-padding {
        padding: 0;
        cursor: default;
      }
      .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }
      .title {
        display: inline;
      }
      .user-menu {
        float: right;
      }
    }
  }

  .side-bar {
    .logo {
      font-weight: bold;
      color: rgb(255, 255, 255);
      text-align: center;
      vertical-align: middle;
      background: rgba(255, 255, 255, 0.2);
      margin: 16px 12px;
      padding: 8px 2px;
    }

    .version {
      font-weight: bold;
      color: rgb(255, 255, 255);
      text-align: center;
      vertical-align: middle;
      margin: 16px 12px;
      padding: 8px 2px;
      position: absolute;
      bottom: 0;
    }
  }

  .content {
    margin-top: 10px;
    padding: 24px;
    background: #fff;
    min-height: 280px;
    overflow: auto;
  }

  .logout {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
