.login {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-card-head {
    min-height: unset;
    padding: unset;
    border-bottom: unset;
    .ant-card-extra {
      padding: unset;
    }
  }
  .form {
    width: 300px;
    .forgot {
      float: right;
    }
    .submit {
      width: 100%;
    }
  }
}
